import { useState } from "react";

export const useFetchJson = (url) => {
    const [data, setData] = useState(null);

    const fetchData = async () => {
        if(data !== null) return;
        const response = await fetch(url);
        if (!response.ok) return;
        const jsonData = await response.json();
        setData(jsonData);
    }

    return [data, fetchData]
}